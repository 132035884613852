<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        The decomposition of
        <stemble-latex content="$\text{AB(g)}$" />
        to
        <stemble-latex content="$\text{A(g)}$" />
        and
        <stemble-latex content="$\text{B(g)}$" />
        is first order with respect to
        <stemble-latex content="$\text{AB.}$" />
        If the rate constant for the reaction is
        <number-value :value="rateConstant" unit="\text{hr}^{-1}," />
        answer each of the questions below.
      </p>

      <p class="mb-2">
        a) What is the rate of decomposition when
        <chemical-latex content="[AB(g)]=" />
        <number-value :value="concAB" unit="\text{M?}" />
      </p>

      <calculation-input
        v-model="inputs.rate"
        class="mb-6"
        :prepend-text="'$\\text{Rate:}$'"
        :append-text="'$\\text{M/hr}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the half-life of the reaction (in hours)?</p>

      <calculation-input
        v-model="inputs.halfLife"
        class="mb-6"
        :prepend-text="'$\\text{t}_{1/2}:$'"
        :append-text="'$\\text{hr}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) If the initial pressure of
        <chemical-latex content="AB(g)" />
        in the reaction flask is
        <number-value :value="PiAB" unit="\text{atm,}" />
        determine the pressure of <b>all the gases</b>,
        <stemble-latex content="$\text{P}_{\text{tot}},$" />
        after the reaction has proceeded for one half-life.
      </p>

      <calculation-input
        v-model="inputs.Ptot"
        prepend-text="$\text{P}_{\text{tot}}:$"
        append-text="$\text{atm}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question312',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rate: null,
        halfLife: null,
        Ptot: null,
      },
    };
  },
  computed: {
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    concAB() {
      return this.taskState.getValueBySymbol('concAB').content;
    },
    PiAB() {
      return this.taskState.getValueBySymbol('PiAB').content;
    },
  },
};
</script>
